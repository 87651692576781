import { VStack } from '@chakra-ui/react'
import React from 'react'
import UserComment from '../userComment'

interface ICommentList {
  commentList: any
}

export default function CommentList({ commentList }: ICommentList) {
  return (
    <VStack overflowY={'scroll'} maxH={700} mt={5} mb={5} align={'start'}>
      {commentList?.data?.map((comment: any) => (
        <UserComment key={comment.id} comment={comment} />
      ))}
    </VStack>
  )
}
