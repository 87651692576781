import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import Plus from '../../../assets/svg/plus'
import PatientForm from '../../forms/patientForm'

export default function PatientFormModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpen}
        rightIcon={<Plus />}
        bg="brand.purple"
        colorScheme="purple"
      >
        New Patient
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent minW={'70%'}>
            <ModalHeader padding={{ lg: '30px 40px' }}>
              New Patient
              <Text fontSize="md" fontWeight={400} mt={5}>
                Enter information for the new patient
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={20}>
              <PatientForm onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}
