import { useCallback } from 'react'

export const usePaginationArray = (
  totalPages: number[],
  currentPage: number
) => {
  const createPaginationArray = useCallback(() => {
    const newArray = []
    if (totalPages.length > 6) {
      if (currentPage > 5) {
        newArray.push(1, 2, 3, '...')
      } else {
        for (let i = 1; i < currentPage - 1; i++) {
          newArray.push(i)
        }
      }
      let start = Math.max(currentPage - 1, 1)
      let end = Math.min(currentPage + 1, totalPages.length)
      for (let i = start; i <= end; i++) {
        newArray.push(i)
      }
      if (currentPage < totalPages.length - 3) {
        newArray.push(
          '...',
          totalPages.length - 2,
          totalPages.length - 1,
          totalPages.length
        )
      } else if (currentPage >= totalPages.length - 3) {
        for (let i = end + 1; i <= totalPages.length; i++) {
          newArray.push(i)
        }
      }
    } else {
      for (let i = 1; i < totalPages.length + 1; i++) {
        newArray.push(i)
      }
    }
    return newArray
  }, [totalPages, currentPage])

  return createPaginationArray()
}
