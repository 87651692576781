import axiosClient from '../axios'
import { useMutation } from '@tanstack/react-query'

export function usePostProtected(url: string) {
  return useMutation({
    mutationKey: [url],
    mutationFn: (postData) => {
      return axiosClient.post(url, postData)
    },
  })
}
