import { MenuItem, Wrap } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import CellImageBox from '../../../../cellImageBox'
import { useLocation, useNavigate } from 'react-router-dom'

interface ICellView {
  setTabIndex: (i: number) => void
  images: any[]
}

export default function CellView({ images, setTabIndex }: ICellView) {
  const navigate = useNavigate()
  const location = useLocation()

  const openCell = (id: string) => {
    setTabIndex(1)
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('cellId', id)
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  useEffect(() => {
    return () => setTabIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrap spacing="20px" justify={'center'}>
      {images?.map((cell) => (
        <CellImageBox key={cell.id} cell={cell}>
          <MenuItem onClick={() => openCell(cell.id)}>Cell view</MenuItem>
        </CellImageBox>
      ))}
    </Wrap>
  )
}
