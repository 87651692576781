import { FormControl, FormErrorMessage, Input, Text } from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface IControlInput {
  label?: string
  isInvalid?: boolean
  placeholder?: string
  errorMessage?: string
  register?: UseFormRegisterReturn
}

export default function ControlInput({
  label,
  isInvalid,
  placeholder,
  errorMessage,
  register,
  ...props
}: IControlInput) {
  return (
    <FormControl
      {...props}
      isInvalid={isInvalid}
      color={'brand.lightGray'}
      mt={label && 4}
    >
      {label && <Text>{label}</Text>}
      <Input
        {...register}
        borderRadius={8}
        placeholder={placeholder}
        _placeholder={{ color: 'brand.lightGray' }}
        background={'white'}
        mt={1}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
