import React from 'react'

function Document() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#CC61FF"
        fillRule="evenodd"
        d="M11.875 2.083a.208.208 0 00-.208-.208H5.834a2.292 2.292 0 00-2.292 2.292v11.666a2.292 2.292 0 002.292 2.292h8.333a2.291 2.291 0 002.292-2.292v-8.21a.208.208 0 00-.209-.209H12.5a.625.625 0 01-.625-.625V2.083zm.625 8.125a.625.625 0 010 1.25h-5a.625.625 0 110-1.25h5zm0 3.334a.625.625 0 110 1.25h-5a.625.625 0 010-1.25h5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#CC61FF"
        d="M13.125 2.357c0-.153.16-.25.28-.155.1.082.192.177.27.285l2.51 3.498c.057.08-.005.183-.103.183h-2.749a.208.208 0 01-.208-.208V2.357z"
      ></path>
    </svg>
  )
}

export default Document
