import { useMutation, useQuery } from '@tanstack/react-query'
import axiosClient from '../../axios'
import { useGet } from '../../query/useGet'
import { useGetPatient } from '../patients'
import { useSearchParams } from 'react-router-dom'

export function useNewDiagnostics() {
  const { data: patientData } = useGetPatient()
  const newDiagnostics = useMutation({
    mutationKey: ['NewDiagnostics'],
    mutationFn: (files: any[]) => {
      const formData = new FormData()
      formData.append('patientId', patientData.id)
      files.map((file) => formData.append('files', file))
      return axiosClient.post(`/diagnostics`, formData)
    },
  })
  return newDiagnostics
}

export function useGetAllDiagnostics() {
  const { data: patientData } = useGetPatient()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('diagnosticsPage')

  const allDiagnostics = useGet(
    `/patients/${patientData?.id}/diagnostics?page=${page || 1}&sortFiled=string&sortDirection=ASC`
  )
  return allDiagnostics
}

export function useGetImageCellsNew2(path: string) {
  const invalidPath =
    !path.includes('//') &&
    !path.includes('/undefined') &&
    !path.includes('/null')

  const getVoteForCell = useQuery({
    enabled: invalidPath,
    staleTime: 50000,
    queryKey: [path],
    retry: 1,
    queryFn: async () => {
      return await axiosClient.get(path)
    },
  })

  const result = getVoteForCell.data?.data

  return { result, ...getVoteForCell }
}
