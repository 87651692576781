import React from 'react'

function Crop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g
        stroke="#344054"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_884_303)"
      >
        <path d="M4.598.75L4.5 12A1.5 1.5 0 006 13.5h11.25"></path>
        <path d="M.75 4.598L12 4.5A1.5 1.5 0 0113.5 6v11.25"></path>
      </g>
      <defs>
        <clipPath id="clip0_884_303">
          <path fill="#fff" d="M0 0H18V18H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Crop
