import { Avatar, Box, Flex, Heading, Text } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'

interface IUserComment {
  comment?: any
}
const DECLINE_REASON: any = {
  poorQuality: 'Poor quality result',
  prescreenSample: 'Rescreen the sample',
  disagreeResults: 'Disagree with the proposed results',
  needExpertise: 'Need additional expertise',
}

export default function UserComment({ comment }: IUserComment) {
  return (
    comment && (
      <Flex gap={4} pt={3}>
        <Box>
          <Avatar name={comment?.contactName} src={comment.owner?.avatarUrl} />
        </Box>
        <Box>
          <Box mb={1}>
            <Heading
              as="h2"
              size="sm"
              textAlign={'start'}
              display={'flex'}
              alignItems={'center'}
              gap={2}
              fontFamily={'Poppins'}
            >
              {`Dr. ${comment?.owner?.contactName}`}
              <Text
                color={'brand.lightGrayText'}
                fontSize="sm"
                fontWeight={400}
              >
                {moment(comment.updatedAt).format('hh:mm A MMM DD')}
              </Text>
            </Heading>
            {comment.vote === 'declined' && (
              <Flex gap={1}>
                <Text fontSize={14} fontStyle={'italic'}>
                  Reasons for decline:
                </Text>
                <Text fontSize={14} fontStyle={'italic'}>
                  {comment?.declineReason?.map(
                    (item: string, i: number, arr: any[]) => (
                      <React.Fragment
                        key={i}
                      >{`${DECLINE_REASON[item]}${i === arr?.length - 1 ? '' : ', '}`}</React.Fragment>
                    )
                  )}
                </Text>
              </Flex>
            )}
          </Box>
          <Text fontSize="md" textAlign={'start'} color={'brand.lightGrayText'}>
            {comment?.commentText}
          </Text>
        </Box>
      </Flex>
    )
  )
}
