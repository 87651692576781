import React from 'react'

function Trash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#344054"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2 4h12M12.667 4v9.333a1.334 1.334 0 01-1.334 1.334H4.667a1.333 1.333 0 01-1.334-1.334V4m2 0V2.667a1.333 1.333 0 011.334-1.334h2.666a1.334 1.334 0 011.334 1.334V4M6.667 7.333v4M9.333 7.333v4"
      ></path>
    </svg>
  )
}

export default Trash
