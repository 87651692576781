import { Box } from '@chakra-ui/react'
import SidBarInfo from './sideBarInfo'
import PatientMain from './patientMain'

export default function PatientCard() {
  return (
    <Box display={'flex'} h={'100%'}>
      <SidBarInfo />
      <Box p={{ base: '32px 16px', lg: '32px 100px' }} w={'100%'}>
        <PatientMain />
      </Box>
    </Box>
  )
}
