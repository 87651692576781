import { Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import { useState } from 'react'

interface IPatientData {
  title: string
  info: string
  iconCopy?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

export default function InfoItem({ title, info, iconCopy }: IPatientData) {
  const [isCopy, setIsCopy] = useState(false)

  const makeCopy = (text: string) => {
    navigator.clipboard.writeText(text)
    setIsCopy(true)
  }

  return (
    <Flex alignItems={'center'}>
      <Text minW={'50%'} fontSize="sm" color={'brand.lightGrayText'}>
        {title}
      </Text>
      <Text fontSize="sm" maxW={150}>
        {iconCopy ? info?.slice(0, 6) : info}
      </Text>
      {iconCopy && (
        <Tooltip
          label={isCopy ? 'Copied' : 'Copy'}
          onClose={() => setIsCopy(false)}
          closeDelay={1000}
        >
          <IconButton
            onClick={() => makeCopy(info)}
            colorScheme="#999FA9"
            aria-label="Copy ID"
            icon={iconCopy}
          />
        </Tooltip>
      )}
    </Flex>
  )
}
