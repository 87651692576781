import { Heading, Image, Link } from '@chakra-ui/react'
import Logo from '../../assets/Logo.png'

export default function MainLogo() {
  return (
    <Heading>
      <Link href="/">
        <Image objectFit="cover" src={Logo} alt="Logo Carefl.ai" />
      </Link>
    </Heading>
  )
}
