import {
  Box,
  Button,
  ButtonProps,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import ImageIcon from '../../../assets/svg/image'
import CustomTab from '../../CustomTab'
import InfectedOnly from './Panels/InfectedOnlyPanel'
import AIGuidancePanel from './Panels/AIGuidancePanel'
import MyCollection from './Panels/MyCollectionPanel'
import { useContext, useState } from 'react'
import CommentPanel from './Panels/CommentPanel'
import { CommentContext } from '../../../context/commentContext'
import Arrow from '../../../assets/svg/arrow'
import MalariaNegativeCellImage from './modals/malariaNegativeCellmage'
import MalariaDetectedCellImage from './modals/malariaDetectedCellImage'
import DeclineCellImage from './modals/declineCellImage'
import InviteColleagues from '../../inviteColleagues'

interface ICellImage extends ButtonProps {
  icon?: boolean
  diagnosticID: string
  buttonText: string
}

export default function CellImageModal({
  diagnosticID,
  buttonText,
  icon = true,
  ...props
}: ICellImage) {
  const { isComment, toggleComment } = useContext(CommentContext)
  const { cellIsFailed } = useContext(CommentContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <>
      <Button
        leftIcon={icon ? <ImageIcon /> : undefined}
        onClick={onOpen}
        fontSize={14}
        {...props}
      >
        {buttonText}
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent minW={'70%'}>
            <ModalHeader
              padding={{ lg: '30px 40px' }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Button
                leftIcon={<Arrow />}
                bg={''}
                onClick={() => (isComment ? toggleComment() : onClose())}
              >
                {isComment ? 'Comments' : 'Cell Images'}
              </Button>
              <Box>
                {tabIndex === 2 && (
                  <InviteColleagues diagnosticID={diagnosticID} />
                )}
              </Box>
            </ModalHeader>

            <ModalBody pb={5}>
              <Tabs
                onChange={(index) => setTabIndex(index)}
                variant="soft-rounded"
                align="center"
                minH={500}
                index={isComment ? 3 : tabIndex}
              >
                {!isComment && (
                  <TabList
                    bg={'#E8E8E8'}
                    width={'fit-content'}
                    borderRadius={20}
                    padding={0.5}
                  >
                    <CustomTab>AI Guidance</CustomTab>
                    <CustomTab>Infected only</CustomTab>
                    <CustomTab>My collection</CustomTab>
                  </TabList>
                )}

                <TabPanels>
                  <TabPanel>
                    <AIGuidancePanel diagnosticID={diagnosticID} />
                  </TabPanel>
                  <TabPanel>
                    <InfectedOnly
                      diagnosticID={diagnosticID}
                      rerenderTrigger={tabIndex}
                    />
                  </TabPanel>
                  <TabPanel>
                    <MyCollection
                      diagnosticID={diagnosticID}
                      rerenderTrigger={tabIndex}
                    />
                  </TabPanel>
                  <TabPanel>
                    <CommentPanel />
                  </TabPanel>
                </TabPanels>
              </Tabs>
              {!isComment &&
                (cellIsFailed && tabIndex === 0 ? (
                  <Text
                    fontSize={'xl'}
                    textAlign={'center'}
                    fontWeight={600}
                    color={'red'}
                  >
                    Something went wrong with analyzing the image
                  </Text>
                ) : (
                  <Center>
                    <Flex gap={10}>
                      <DeclineCellImage diagnosticID={diagnosticID} />
                      <MalariaDetectedCellImage diagnosticID={diagnosticID} />
                      <MalariaNegativeCellImage diagnosticID={diagnosticID} />
                    </Flex>
                  </Center>
                ))}
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}
