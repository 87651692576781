import React from 'react'

function MalariaDetectedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="56"
      fill="none"
      viewBox="0 0 57 56"
    >
      <rect width="48" height="48" x="4.5" y="4" fill="#FEE4E2" rx="24"></rect>
      <rect
        width="48"
        height="48"
        x="4.5"
        y="4"
        stroke="#FEF3F2"
        strokeWidth="8"
        rx="24"
      ></rect>
      <path
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M28.5 38c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
      ></path>
    </svg>
  )
}

export default MalariaDetectedIcon
