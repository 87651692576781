import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
} from '@chakra-ui/react'
import ThreeDots from '../../../../assets/svg/ThreeDots'
import InfoItem from './infoItems'
import Copy from '../../../../assets/svg/copy'
import { useGetPatient } from '../../../../api/api_hooks/patients'
import moment from 'moment'

export default function SidBarInfo() {
  const { data: patientData } = useGetPatient()

  const yearOld = ` (${moment().diff(moment(patientData?.dateOfBirth), 'years')} y.o)`
  const dateOFBird = `${moment(patientData?.dateOfBirth).format('DD MMM YYYY')}`

  return (
    <Flex
      p={'32px 16px'}
      bg={'white'}
      direction={'column'}
      height={'100%'}
      width={450}
      position={'sticky'}
      fontFamily={'Poppins'}
    >
      <Center gap={2} justifyContent={'space-between'}>
        <Center>
          <Avatar size="md" name="O K" src={''} border={'4px solid white'} />
          <Heading fontFamily={'Poppins'} as={'p'} size="md" maxW={200}>
            {patientData?.fullName}
          </Heading>
        </Center>
        <Box>
          <IconButton
            aria-label="More info"
            icon={<ThreeDots />}
            bg={'white'}
          />
        </Box>
      </Center>
      <Divider mt={6} mb={4} />

      <Box>
        <Heading fontFamily={'Poppins'} as={'p'} size="md">
          Patient info
        </Heading>
        <Flex gap={6} direction={'column'} mt={5}>
          <InfoItem
            title="Patient ID"
            info={patientData?.id}
            iconCopy={<Copy />}
          />
          <InfoItem title="Sex" info={patientData?.gender} />
          <InfoItem title="Date of birth" info={`${dateOFBird} ${yearOld}`} />
          <InfoItem title="Home Address" info={patientData?.address} />
        </Flex>
      </Box>
    </Flex>
  )
}
