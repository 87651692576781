import React, { useEffect } from 'react'
import SharedCase from './sharedCase'
import { Box, Text, VStack, useToast } from '@chakra-ui/react'
import { useGetSharedDiagnostic } from '../../../api/api_hooks/diagnostics/shared-collection'
import Pagination from '../../pagination'
import { useConfirmInvited } from '../../../api/api_hooks/invite'
import { useNavigate } from 'react-router-dom'
import { useGetPrams } from '../../../utils/getPrams'

export default function MyCollaboratory() {
  const toast = useToast()
  const navigate = useNavigate()
  const invitationId = useGetPrams('invitationId')

  const { data, refetch } = useGetSharedDiagnostic()
  const { mutateAsync } = useConfirmInvited()

  useEffect(() => {
    if (invitationId)
      mutateAsync({
        id: invitationId,
        postData: { status: 'accepted' },
      })
        .then(() => {
          refetch()
        })
        .catch((e) => {
          const errorText =
            e?.response?.data?.message === 'Invitation not found.'
          toast({
            title: errorText
              ? 'Invite was confirm'
              : 'Something went wrong try again',
            position: 'top',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        })
        .finally(() => navigate(`?invitationId=`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId])

  return (
    <Box overflow={'scroll'} h="80vh" gap={4}>
      <Text mb={5}>
        View questionable cases under verification and share your thoughts
      </Text>
      <VStack align="stretch" gap={5}>
        {data?.data?.map((sharedCase: any) => (
          <SharedCase key={sharedCase.id} data={sharedCase} />
        ))}
      </VStack>
      <Pagination data={data} pageName="sharedCasePage" />
    </Box>
  )
}
