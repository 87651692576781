import React, { useState } from 'react'
import { useGetAllDiagnostics } from '../../../../api/api_hooks/diagnostics'
import { useMalariaDetected } from '../../../../api/api_hooks/diagnostics/results'
import DeclineModal from '../../../resultModal/DeclineModal'
import { useToast } from '@chakra-ui/react'

interface IProps {
  diagnosticID?: string
}

export default function DeclineCellImage({ diagnosticID }: IProps) {
  const toast = useToast()
  const [trigger, setTrigger] = useState(0)

  const { refetch } = useGetAllDiagnostics()
  const { mutateAsync } = useMalariaDetected()

  const confirm = (data: any) => {
    if (data.multiple.length && data.thoughts) {
      mutateAsync({
        id: diagnosticID,
        data: {
          commentText: data.thoughts,
          decision: 'declined',
          declineReason: data.multiple,
        },
      })
        .then(() => {
          toast({
            title: 'Diagnostic - declined is send',
            position: 'top',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          refetch()
          setTrigger(trigger + 1)
        })
        .catch(() => {
          toast({
            title: 'Something went wrong try again',
            position: 'top',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        })
    } else {
      toast({
        title: 'Write a comment',
        position: 'top',
        status: 'info',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return <DeclineModal closeTrigger={trigger} confirm={confirm} />
}
