import {
  Button,
  Center,
  Flex,
  Highlight,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { DragEvent, useEffect, useState } from 'react'
import Uploader from '../../assets/svg/uploader'
import ListUploadedCells from './ListUploadedCells'
import {
  useGetAllDiagnostics,
  useNewDiagnostics,
} from '../../api/api_hooks/diagnostics'

interface IDraAndDrop {
  onClose: () => void
}

export default function DraAndDrop({ onClose }: IDraAndDrop) {
  const toast = useToast()
  const [isOver, setIsOver] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const [isSizeError, setIsSizeError] = useState<string>('')
  const { refetch } = useGetAllDiagnostics()

  const { mutate, isSuccess, isError } = useNewDiagnostics()
  const maximumSizeOFFile = 10
  const minimumSizeOFFile = 0.5

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(true)
  }
  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(false)
  }
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(false)
    const droppedFiles = Array.from(event.dataTransfer.files)

    const filteredFiles = droppedFiles.filter((file) => {
      const sizeInMegabytes = file.size / (1024 * 1024)
      return (
        sizeInMegabytes <= maximumSizeOFFile &&
        sizeInMegabytes > minimumSizeOFFile
      )
    })

    if (droppedFiles.length < 6 && files.length + droppedFiles.length < 6) {
      uploadFile(filteredFiles)
    } else {
      setIsSizeError(`Can only upload 5 files`)
    }
  }

  const browseFiles = (files: FileList | null) => {
    //@ts-ignore
    if (files) uploadFile([...files])
  }

  const uploadFile = (filesF: File[]) => {
    if (files.length > 4) {
      setIsSizeError(`Can only upload 5 files`)
      return
    }
    const fileExist = files.find((item) => item.name === filesF[0].name)

    const allowedExtensions = ['svg', 'png', 'jpeg', 'gif']
    const allowedExtensionsArray = filesF.filter((fileName) => {
      const result = fileName.type.split('/').pop()?.toLowerCase()
      if (result) return allowedExtensions.includes(result)
      else return []
    })

    allowedExtensionsArray.forEach((file) => {
      const sizeInMegabytes = file.size / (1024 * 1024)
      const allowSize =
        sizeInMegabytes < maximumSizeOFFile &&
        sizeInMegabytes > minimumSizeOFFile
      if (allowSize) {
        if (!fileExist) {
          setIsSizeError('')
          setFiles([...files, ...filesF])
          const reader = new FileReader()
          reader.onerror = () => {
            setIsSizeError('There was an issue reading the file.')
          }
          reader.readAsDataURL(file)
          return reader
        } else {
          setIsSizeError('File already uploaded')
        }
      } else {
        setIsSizeError(
          `Size of File must be between ${minimumSizeOFFile}MB and ${maximumSizeOFFile}MB`
        )
      }
    })
  }

  const removeFile = (fileName: string) => {
    const removedArray = files.filter((file) => file.name !== fileName)
    setFiles(removedArray)
  }

  useEffect(() => {
    if (!files.length) setIsSizeError('')
  }, [files])

  const onSubmit = () => {
    if (files.length === 0) {
      toast({
        title: 'Please add cell images',
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } else {
      mutate(files)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: `Test start - success`,
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      refetch()
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  if (isError) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      <Center flexDirection={'column'} h={'100%'} overflow={'scroll'} pb={3}>
        <Flex
          alignItems={'center'}
          direction={'column'}
          gap={2}
          border={'dashed #E0E0E0'}
          borderRadius={2}
          background={isOver ? '#f9efff' : ''}
          p={5}
          {...{
            onDragOver: handleDragOver,
            onDragLeave: handleDragLeave,
            onDrop: handleDrop,
          }}
        >
          <Uploader />
          <Text fontSize={'xl'}>
            <Input
              onChange={(e) => browseFiles(e.target.files)}
              w={'150px'}
              h={'25px'}
              position={'absolute'}
              zIndex={1}
              cursor={'pointer'}
              borderRadius={100}
              opacity={0}
              type="file"
              name="myImage"
            />
            <Highlight
              query="Click to upload"
              styles={{
                color: 'brand.purple',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Click to upload or drag and drop
            </Highlight>
          </Text>
          <Text color={'brand.lightGrayText'}>SVG, PNG, JPG or GIF</Text>
          <Text color={'brand.lightGrayText'}>
            File must be between 0.5 - 10MB, maximum 5 files
          </Text>
          <Text color={'red'}>{isSizeError} </Text>
        </Flex>
        <ListUploadedCells
          fileCells={files}
          removeFile={(fileName) => removeFile(fileName)}
        />
      </Center>

      <Flex w={'100%'} gap={2}>
        <Button variant={'outline'} w={'100%'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          bg={'brand.purple'}
          w={'100%'}
          color={'white'}
          onClick={onSubmit}
        >
          Start Testing
        </Button>
      </Flex>
    </Flex>
  )
}
