import SwiperSlider from '../../../../SwiperSlider'
import { Box, useToast } from '@chakra-ui/react'
import { useGetImageCellsNew2 } from '../../../../../api/api_hooks/diagnostics'

export default function AIGuidancePanel({
  diagnosticID,
}: {
  diagnosticID?: string
}) {
  const toast = useToast()
  const { result, isError } = useGetImageCellsNew2(
    `diagnostics/${diagnosticID}/cellular-analysis-images?page=1&sortFiled=string&sortDirection=ASC`
  )

  if (isError) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <Box>
      <SwiperSlider photoData={result?.data} />
    </Box>
  )
}
