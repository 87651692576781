import React from 'react'

function ZoomOut() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#344054"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21 21l-4.35-4.35M8 11h6m5 0a8 8 0 11-16 0 8 8 0 0116 0z"
      ></path>
    </svg>
  )
}

export default ZoomOut
