import { Box, Center, Image } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import CommentList from '../../../../commentComponents/commentList'
import CommentInput from '../../../../commentComponents/commentInput/commentInput'
import { useGetShareCell } from '../../../../../api/api_hooks/vote'
import { useGetCellComments } from '../../../../../api/api_hooks/comments'
import MalariaNegativeCollaboratory from '../../modals/malariaNegativeCollaboratory'
import MalariaDetectedCollaboratory from '../../modals/malariaDetectedCollaboratory'
import DeclineCollaboratory from '../../modals/declineCollaboratory'
import { useGetPrams } from '../../../../../utils/getPrams'
import { useRemoveParam } from '../../../../../utils/useRemoveParam'

export default function CommentCellView() {
  const cellId = useGetPrams('cellId')
  const removeParam = useRemoveParam()

  const { result: shareCell } = useGetShareCell(
    `shared-collections/image-cells/${cellId || null}`
  )
  const { result: shareCellComments, refetch } = useGetCellComments(
    `/image-cells/${cellId}/comments`
  )

  useEffect(() => {
    refetch()
    return () => removeParam('cellId')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <Center>
        <Image
          w={120}
          h={120}
          borderRadius={8}
          alt=""
          src={shareCell?.mediaUrl}
        />
      </Center>
      <CommentList commentList={shareCellComments} />
      <CommentInput refetch={refetch} />
      <Center gap={10} mt={6}>
        <DeclineCollaboratory />
        <MalariaNegativeCollaboratory />
        <MalariaDetectedCollaboratory />
      </Center>
    </Box>
  )
}
