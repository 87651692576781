import { Box } from '@chakra-ui/react'
import PatientsBox from '../../components/patientsBox'

export default function Patients() {
  return (
    <Box p={{ base: '32px 16px', lg: '32px 100px' }} h={'100%'}>
      <PatientsBox />
    </Box>
  )
}
