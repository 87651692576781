import React from 'react'
import { MenuItem, useToast } from '@chakra-ui/react'
import CellImageBox from '../../../../../cellImageBox'
import { useAddMyCollection } from '../../../../../../api/api_hooks/diagnostics/myCollection'
import { useDeleteFromInfected } from '../../../../../../api/api_hooks/diagnostics/infected'
import { useGetAllDiagnostics } from '../../../../../../api/api_hooks/diagnostics'

interface ICellImageBox {
  cell: any
  updateImageCells: () => void
}
export default function InfectedOnlyCellImageBox({
  cell,
  updateImageCells,
}: ICellImageBox) {
  const toast = useToast()
  const { mutateAsync } = useAddMyCollection()
  const { mutateAsync: deleteFromI, isError: errorDeleteCells } =
    useDeleteFromInfected()
  const { refetch } = useGetAllDiagnostics()

  const addToMyCollention = () => {
    mutateAsync({ imageCellId: cell.id } as unknown as void)
      .then((e) => {
        toast({
          title: `Cell added to collection - success`,
          position: 'top',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(() =>
        toast({
          title: 'Something went wrong try again',
          position: 'top',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      )
  }

  const deleteFromInfected = () => {
    deleteFromI(cell.id).then((e) => {
      updateImageCells()
      refetch()
      toast({
        title: `Cell deleted from collection - success`,
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    })
  }

  if (errorDeleteCells) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <CellImageBox cell={cell}>
      <MenuItem onClick={addToMyCollention}>Add to My collection</MenuItem>
      <MenuItem onClick={deleteFromInfected}>Delete from infected</MenuItem>
    </CellImageBox>
  )
}
