import { Box, IconButton, Text } from '@chakra-ui/react'
import Plus from '../../../assets/svg/plus'

interface IListUploadedCells {
  fileCells: File[]
  removeFile: (fileName: string) => void
}

export default function ListUploadedCells({
  fileCells,
  removeFile,
}: IListUploadedCells) {
  return (
    <Box
      gap={1}
      display={'flex'}
      flexDirection={'column'}
      mt={5}
      overflow={'scroll'}
    >
      {fileCells.map((file) => (
        <Box
          key={file.name + file.lastModified}
          border={'1px solid #E0E0E0'}
          display={'flex'}
          borderRadius={5}
          p={2}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} gap={3}>
            <Text>{file.name}</Text>
            <Text color={'brand.lightGrayText'}>
              ({(file.size / (1024 * 1024)).toFixed(1)}Mb)
            </Text>
          </Box>
          <Box>
            <IconButton
              colorScheme=""
              aria-label="Remove Upload Cell"
              size="sm"
              onClick={() => removeFile(file.name)}
              icon={
                <Box style={{ rotate: '135deg' }}>
                  <Plus fill="#000" />
                </Box>
              }
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
