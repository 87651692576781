import React, { createContext, useState } from 'react'
import { arrayVoid, booleanVoid, noop, numberVoid } from '../utils/noop'

export const CommentContext = createContext({
  toggleComment: noop,
  checkCommentType: numberVoid,
  isComment: false,
  commentType: 0,
  myCollection: [],
  setToCollection: arrayVoid,
  toggleCellStatus: booleanVoid,
  cellIsFailed: false,
})

export default function CommentProvider({ children }: any) {
  const [isComment, setIsComment] = useState(false)
  const [commentType, setCommentType] = useState(0)

  const [myCollection, setMyCollection] = useState<any>([])
  const [cellIsFailed, setCellIsFailed] = useState<boolean>(false)

  function toggleComment() {
    setIsComment(!isComment)
  }

  function checkCommentType(index: number) {
    setCommentType(index)
  }

  function setToCollection(array: any[]) {
    setMyCollection(array)
  }

  function toggleCellStatus(status: boolean) {
    setCellIsFailed(status)
  }

  return (
    <CommentContext.Provider
      value={{
        toggleComment,
        checkCommentType,
        setToCollection,
        toggleCellStatus,
        isComment,
        commentType,
        myCollection,
        cellIsFailed,
      }}
    >
      {children}
    </CommentContext.Provider>
  )
}
