import { useMutation } from '@tanstack/react-query'
import { usePostProtected } from '../../query/useProtectedPost'
import axiosClient from '../../axios'

export function useInvited(id: string) {
  const invited = usePostProtected(`/diagnostics/${id}/invitations`)
  return invited
}

export function useConfirmInvited() {
  return useMutation({
    mutationFn: ({ id, postData }: any) => {
      return axiosClient.post(
        `/diagnostics/invitations/${id}/respond`,
        postData
      )
    },
  })
}
