import { Box } from '@chakra-ui/react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form'
import Calendar from '../../assets/svg/calendat'

interface IDataPicker {
  register: UseFormRegisterReturn
  control: Control<any>
  error: boolean | undefined
}

export default function DataPicker({ register, control, error }: IDataPicker) {
  const currentDate = new Date()

  return (
    <Box
      w={'100%'}
      sx={{
        '.react-datepicker-wrapper': {
          width: '100%',
          input: {
            width: '100%',
            marginTop: '4px',
            border: `1px solid ${error ? '#E53E3E' : '#E2E8F0'}`,
            boxShadow: error ? '0 0 0 1px #E53E3E' : '',
            borderRadius: '6px',
            height: '40px',
            padding: '0px 16px',
            '::placeholder': {
              color: 'inherit',
            },
          },
        },
      }}
    >
      <Controller
        control={control}
        name={register.name}
        render={({ field }) => (
          <>
            <DatePicker
              maxDate={currentDate}
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Choose date"
              showIcon
              icon={
                <Box position={'absolute'} right={2} top={1}>
                  <Calendar />
                </Box>
              }
            />
          </>
        )}
      />
    </Box>
  )
}
