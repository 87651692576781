import React, { useContext } from 'react'
import { MenuItem, useToast } from '@chakra-ui/react'
import CellImageBox from '../../../../../cellImageBox'
import { CommentContext } from '../../../../../../context/commentContext'
import { useDeleteFromMyCollection } from '../../../../../../api/api_hooks/diagnostics/myCollection'
import { useNavigate } from 'react-router-dom'

interface ICellImageBox {
  cell: any
  updateMyCollection: () => void
}
export default function MyCollectionCellImageBox({
  cell,
  updateMyCollection,
}: ICellImageBox) {
  const toast = useToast()
  const navigate = useNavigate()

  const { toggleComment } = useContext(CommentContext)
  const { mutateAsync } = useDeleteFromMyCollection()

  const comment = () => {
    toggleComment()
    navigate(`?cellId=${cell.id}`)
  }

  const deleteFromMyCollection = () => {
    mutateAsync(cell.id)
      .then(() => updateMyCollection())
      .catch(() =>
        toast({
          title: 'Something went wrong try again',
          position: 'top',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      )
  }

  return (
    <CellImageBox cell={cell}>
      <MenuItem onClick={comment}>Comments</MenuItem>
      <MenuItem onClick={deleteFromMyCollection}>
        Delete from my collection
      </MenuItem>
    </CellImageBox>
  )
}
