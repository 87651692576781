import { Box, Wrap, useToast } from '@chakra-ui/react'
import MyCollectionCellImageBox from './MyCollectionCellImageBox'
import { useGetImageCellsNew2 } from '../../../../../api/api_hooks/diagnostics'
import { useContext, useEffect } from 'react'
import { CommentContext } from '../../../../../context/commentContext'
import { useGetPrams } from '../../../../../utils/getPrams'
import Pagination from '../../../../pagination'

interface IMyCollection {
  diagnosticID?: string
  rerenderTrigger?: any
}

export default function MyCollection({
  diagnosticID,
  rerenderTrigger,
}: IMyCollection) {
  const { setToCollection } = useContext(CommentContext)

  const toast = useToast()
  const page = useGetPrams('collectionPage')
  const { result, refetch, isError } = useGetImageCellsNew2(
    `diagnostics/${diagnosticID}/shared-collections/image-cells?page=${page || 1}&sortFiled=string&sortDirection=ASC`
  )

  useEffect(() => {
    setToCollection(result?.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  useEffect(() => {
    if (rerenderTrigger === 2) {
      refetch()
    }
    setToCollection(result?.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosticID, rerenderTrigger])

  if (isError) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <Box>
      <Wrap spacing="20px" justify={'center'} mb={5}>
        {result?.data?.map((image: any) => (
          <MyCollectionCellImageBox
            key={image.id}
            cell={image}
            updateMyCollection={refetch}
          />
        ))}
      </Wrap>
      <Pagination data={result} pageName={'collectionPage'} />
    </Box>
  )
}
