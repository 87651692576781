import {
  Box,
  Image,
  Menu,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './cropStyle.css'
import { useAddToInfected } from '../../../api/api_hooks/diagnostics/infected'
import {
  useGetAllDiagnostics,
  useGetImageCellsNew2,
} from '../../../api/api_hooks/diagnostics'

interface IDrawImage {
  image: any
  zoomIn: boolean
  cropToggle: boolean
}

const RENDERED_IMAGE_SIZE: number = 600
const DEFAULT_IMAGE_SIZE: number = 2703
const ZOOM_RATIO: number = 3

function DrawImage({ image, zoomIn, cropToggle }: IDrawImage) {
  const { refetch: updateInfected } = useGetImageCellsNew2(
    `diagnostics/${image.diagnosticId}/image-cells?page=1`
  )
  const { refetch: updateImageAI } = useGetImageCellsNew2(
    `diagnostics/${image.diagnosticId}/cellular-analysis-images?page=1&sortFiled=string&sortDirection=ASC`
  )

  const [customCoordinates, setCustomCoordinates] = useState([
    ...image.coordinates.customPositive,
  ])

  const { refetch } = useGetAllDiagnostics()
  const toast = useToast()
  const { mutateAsync } = useAddToInfected()
  const [crop, setCrop] = useState<Crop>()
  const [cropPx, setCropPx] = useState<Crop>()
  const [open, setOpen] = useState<boolean>(false)

  const zoom: number = zoomIn ? 1.4 : 1
  const top = crop && crop?.y + crop.height
  const left = crop && (crop?.x + crop.width) / zoom

  const resetCrop = () => {
    setCrop({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      unit: '%',
    })
  }

  const scaleFactor =
    RENDERED_IMAGE_SIZE /
    Math.min(
      image.coordinates.width || DEFAULT_IMAGE_SIZE,
      image.coordinates.height || DEFAULT_IMAGE_SIZE
    )

  const sendToInfected = () => {
    if (cropPx && image) {
      const croppedCellData = zoomIn
        ? {
            cellularAnalysisImageId: image.id,
            coordinates: [
              Math.floor(cropPx?.x / scaleFactor / ZOOM_RATIO),
              Math.floor(cropPx?.y / scaleFactor / ZOOM_RATIO),
              Math.floor(
                (cropPx?.x + cropPx?.width) / scaleFactor / ZOOM_RATIO
              ),
              Math.floor(
                (cropPx?.y + cropPx?.height) / scaleFactor / ZOOM_RATIO
              ),
            ],
          }
        : {
            cellularAnalysisImageId: image.id,
            coordinates: [
              Math.floor(cropPx?.x / scaleFactor),
              Math.floor(cropPx?.y / scaleFactor),
              Math.floor((cropPx?.x + cropPx?.width) / scaleFactor),
              Math.floor((cropPx?.y + cropPx?.height) / scaleFactor),
            ],
          }

      mutateAsync(croppedCellData)
        .then(() => {
          toast({
            title: 'Cell sending - success',
            position: 'top',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          resetCrop()
          // updateImageAI()
          setCustomCoordinates([
            ...customCoordinates,
            croppedCellData.coordinates,
          ])
          updateInfected()
          refetch()
        })
        .catch(() => {
          resetCrop()
          toast({
            title: 'Cell sending - error',
            position: 'top',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        })
    }
  }

  useEffect(() => {
    resetCrop()
  }, [cropToggle])

  useEffect(() => {
    updateImageAI()
    setCustomCoordinates(image.coordinates.customPositive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  return (
    <div>
      <Box
        transitionDuration={'300ms'}
        transform={`translate3d(0px, 0px, 0px) scale(${zoomIn ? ZOOM_RATIO : 1})`}
        position={'relative'}
        display={'inline-block'}
      >
        {open && crop && crop?.width > 1 && (
          <Box
            position={'absolute'}
            top={`${top}%`}
            left={`${left}%`}
            zIndex={9}
          >
            <Menu isOpen>
              <MenuList transform={`scale(${zoomIn ? 0.4 : 1}) !important`}>
                <MenuItem onClick={sendToInfected}>Add to infected</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
        <ReactCrop
          aspect={1}
          disabled={cropToggle}
          crop={crop}
          onChange={(cropPx, percentCrop) => {
            setCrop(percentCrop)
            setCropPx(cropPx)
          }}
          onDragEnd={() => setOpen(true)}
          locked={cropToggle}
        >
          <Box>
            <Image
              cursor={zoomIn && cropToggle ? 'grab' : ''}
              transform={'none !important'}
              minW={image.coordinates.width * scaleFactor}
              minH={image.coordinates.height * scaleFactor}
              id="dynamicImage"
              alt=""
              src={image.mediaUrl}
            />
          </Box>
          {image?.coordinates?.negative?.map(
            (coordinates: number[], i: number) => {
              const [x1, y1, x2, y2] = coordinates.map(
                (value) => value * scaleFactor
              )
              return (
                <Box
                  key={coordinates.join('-')}
                  position={'absolute'}
                  left={`${x1}px`}
                  top={`${y1}px`}
                  w={`${x2 - x1}px`}
                  h={`${y2 - y1}px`}
                  border={'1.5px solid red'}
                />
              )
            }
          )}
          {image?.coordinates?.positive?.map(
            (coordinates: number[], i: number) => {
              const [x1, y1, x2, y2] = coordinates.map(
                (value) => value * scaleFactor
              )
              return (
                <Box
                  key={coordinates.join('-')}
                  position={'absolute'}
                  left={`${x1}px`}
                  top={`${y1}px`}
                  w={`${x2 - x1}px`}
                  h={`${y2 - y1}px`}
                  border={'1.5px solid blue'}
                />
              )
            }
          )}
          {customCoordinates?.map((coordinates: number[], i: number) => {
            const [x1, y1, x2, y2] = coordinates.map(
              (value) => value * scaleFactor
            )
            return (
              <Box
                key={coordinates.join('-')}
                position={'absolute'}
                left={`${x1}px`}
                top={`${y1}px`}
                w={`${x2 - x1}px`}
                h={`${y2 - y1}px`}
                border={'1.5px solid #CC61FF'}
              />
            )
          })}
        </ReactCrop>
      </Box>
    </div>
  )
}

export const MemoizedDrawImage = React.memo(DrawImage)
