import { useMutation} from "@tanstack/react-query"
import axiosClient from "../axios"

export function usePatch(url: string) {
    return useMutation({
      mutationKey: [url],
      mutationFn: async (postData) => {
        const { data } = await axiosClient.patch(url, postData)
        return data
      },
    })
  }
  