import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import PatientForm from '../../forms/patientForm'
import Edit from '../../../assets/svg/edit'

interface IEditPatientFormModal {
  id: string
}

export default function EditPatientFormModal({ id }: IEditPatientFormModal) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const EditPatient = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    onOpen()
  }

  return (
    <>
      <Button
        onClick={EditPatient}
        fontSize={14}
        fontWeight={400}
        leftIcon={<Edit />}
        bg={''}
      >
        Edit info
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent minW={'70%'}>
            <ModalHeader padding={{ lg: '30px 40px' }}>
              Edit info
              <Text fontSize="md" fontWeight={400} mt={5}>
                Enter information
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={20}>
              <PatientForm id={id} onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}
