import { useContext, useEffect, useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import './styles.css'

// import required modules
import { FreeMode, Navigation, Thumbs, Zoom } from 'swiper/modules'
import { Box, IconButton } from '@chakra-ui/react'
import ZoomIn from '../../assets/svg/zoomIn'
import ZoomOut from '../../assets/svg/zoomOut'
import Crop from '../../assets/svg/crop'
import { MemoizedDrawImage } from './DrawImage'
import { CommentContext } from '../../context/commentContext'

export default function SwiperSlider({ photoData }: any) {
  const { toggleCellStatus } = useContext(CommentContext)
  const [thumbsSwiper, setThumbsSwiper] = useState('')
  const [zoomIn, setZoomIn] = useState(false)
  const [cropToggle, setCropToggle] = useState(true)
  const swiperRef = useRef(null)

  const toggleZoomIn = () => {
    setZoomIn(true)
    //@ts-ignore
    swiperRef.current.swiper.zoom.in()
  }
  const toggleZoomOut = () => {
    setZoomIn(false)
    //@ts-ignore
    swiperRef.current.swiper.zoom.out()
  }

  const crop = () => {
    setCropToggle(!cropToggle)
  }

  const cellClick = (cell: any) => {
    if (cell?.process === 'failed') {
      toggleCellStatus(true)
    } else toggleCellStatus(false)
  }

  useEffect(() => {
    cellClick(photoData?.[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoData])

  return (
    <>
      <Box>
        <Swiper
          //@ts-ignore
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={7}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
          navigation
        >
          {photoData?.map((image: any) => (
            <SwiperSlide key={image.id} onClick={() => cellClick(image)}>
              <img alt="" src={image.mediaUrl} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box display={'flex'} justifyContent={'center'} gap={5} mb={3}>
        <Box
          background={!cropToggle ? '#CC61FF' : ''}
          borderRadius={8}
          border={'1px solid #D0D5DD'}
        >
          <IconButton
            colorScheme=""
            aria-label="Search database"
            onClick={crop}
            icon={<Crop />}
          />
        </Box>
        <Box borderRadius={8} border={'1px solid #D0D5DD'}>
          <IconButton
            colorScheme=""
            aria-label="Search database"
            icon={<ZoomIn />}
            onClick={toggleZoomIn}
          />
          <IconButton
            colorScheme=""
            aria-label="Search database"
            icon={<ZoomOut />}
            onClick={toggleZoomOut}
          />
        </Box>
      </Box>
      <Swiper
        style={{
          //@ts-ignore
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        spaceBetween={10}
        thumbs={{
          //@ts-ignore
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs, Zoom]}
        className="mySwiper2"
        zoom
        allowTouchMove={!cropToggle}
        ref={swiperRef}
        onZoomChange={() => {
          setZoomIn(!zoomIn)
        }}
      >
        {photoData?.map((image: any) => (
          <SwiperSlide key={image.id} style={{ overflow: 'hidden' }}>
            <div className={cropToggle ? 'swiper-zoom-container' : ''}>
              <MemoizedDrawImage
                image={image}
                zoomIn={zoomIn}
                cropToggle={cropToggle}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
