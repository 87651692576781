import { useSearchParams } from 'react-router-dom'
import { useGet } from '../../../query/useGet'

export function useGetSharedDiagnostic() {
  const [searchParams] = useSearchParams()
  const page = searchParams.get('sharedCasePage')
  const allDiagnostics = useGet(
    `shared-collections?page=${page}&limit=20&sortFiled=string&sortDirection=ASC`
  )
  return allDiagnostics
}
