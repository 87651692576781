import React from 'react'

function Camera() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        clipPath="url(#clip0_470_19385)"
      >
        <path d="M16.7 13.902a1.4 1.4 0 01-1.4 1.4H2.7a1.4 1.4 0 01-1.4-1.4v-7.7a1.4 1.4 0 011.4-1.4h2.8l1.4-2.1h4.2l1.4 2.1h2.8a1.4 1.4 0 011.4 1.4v7.7z"></path>
        <path d="M9 12.499a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6z"></path>
      </g>
      <defs>
        <clipPath id="clip0_470_19385">
          <path
            fill="#fff"
            d="M0 0H16.8V16.8H0z"
            transform="translate(.6 .6)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Camera
