import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import ImageIcon from '../../../../assets/svg/image'
import Arrow from '../../../../assets/svg/arrow'
import CellView from '../tabs/cellView'
import CommentCellView from '../tabs/commentCellView'

interface IViewCellImagesModal {
  images: any[]
}

export default function ViewCellImagesModal({ images }: IViewCellImagesModal) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div>
      <Button
        onClick={onOpen}
        leftIcon={<ImageIcon />}
        color={'white'}
        background="brand.purple"
      >
        View Cell Images
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent minW={'70%'}>
            <ModalHeader display={'flex'} gap={3}>
              <Button
                _hover={{ bg: '' }}
                _active={{ bg: '' }}
                p={0}
                bg={''}
                onClick={() => (tabIndex ? setTabIndex(0) : onClose())}
                leftIcon={<Arrow />}
              >
                {tabIndex
                  ? 'Cell View'
                  : 'Questionable cases under verification'}
              </Button>
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={5}>
              <Tabs
                variant="unstyled"
                display={'flex'}
                flexDirection={'column'}
                h={'100%'}
                fontFamily={'Poppins'}
                index={tabIndex}
              >
                <TabPanels overflowY={'scroll'}>
                  <TabPanel>
                    <CellView
                      images={images}
                      setTabIndex={(i: number) => setTabIndex(i)}
                    />
                  </TabPanel>
                  <TabPanel>
                    <CommentCellView />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </div>
  )
}
