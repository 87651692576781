import { useMutation } from '@tanstack/react-query'
import { usePostProtected } from '../../../query/useProtectedPost'
import axiosClient from '../../../axios'

export function useAddMyCollection() {
  const addMyCollection = usePostProtected(`/shared-collections/image-cells`)
  return addMyCollection
}

export function useDeleteFromMyCollection() {
  const deletePatient = useMutation({
    mutationKey: ['/deleteFromMyCollection'],
    mutationFn: (id: string) => {
      return axiosClient.delete(`/shared-collections/image-cell/${id}`)
    },
  })
  return deletePatient
}
