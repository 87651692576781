import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './theme/theme'
import { RouterProvider } from 'react-router-dom'
import CommentProvider from './context/commentContext'
import AuthProvider from './context/Auth'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { router } from './routing/root'

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <CommentProvider>
          <ChakraProvider theme={theme}>
            <RouterProvider router={router} />
          </ChakraProvider>
        </CommentProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
