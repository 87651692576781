import { Box, Image } from '@chakra-ui/react'
import CommentInput from '../../../../commentComponents/commentInput/commentInput'
import CommentList from '../../../../commentComponents/commentList'
import { useGetCellComments } from '../../../../../api/api_hooks/comments'
import { useGetPrams } from '../../../../../utils/getPrams'
import { useGetShareCell } from '../../../../../api/api_hooks/vote'

export default function CommentPanel() {
  const cellId = useGetPrams('cellId')

  const { result: cellComments, refetch } = useGetCellComments(
    `/image-cells/${cellId}/comments`
  )

  const { result: shareCell } = useGetShareCell(
    `shared-collections/image-cells/${cellId || null}`
  )

  return (
    <Box>
      <Image
        borderRadius={8}
        w={120}
        h={120}
        bg={'grey'}
        alt=""
        src={shareCell?.mediaUrl || cellComments?.data[0]?.imageCell?.mediaUrl}
      />
      <CommentList commentList={cellComments} />
      <Box>
        <CommentInput refetch={refetch} />
      </Box>
    </Box>
  )
}
