import { Tab, TabProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface ICustomTab extends TabProps {
  children: ReactNode
}

export default function CustomTab({ children, ...props }: ICustomTab) {
  return (
    <Tab
      {...props}
      fontSize={'sm'}
      fontWeight={400}
      _selected={{ color: 'white', bg: 'brand.purple' }}
    >
      {children}
    </Tab>
  )
}
