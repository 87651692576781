import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import Plus from '../../../assets/svg/plus'
import DraAndDrop from '../../dragAndDrop'

export default function NewTestModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Plus />}
        bg="brand.purple"
        colorScheme="purple"
      >
        New test
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent minW={'70%'} maxH={'80%'}>
            <>
              <ModalHeader padding={{ lg: '30px 40px' }}>
                New Testing
              </ModalHeader>
              <ModalCloseButton />
            </>
            <ModalBody pb={10} display={'flex'} overflow={'scroll'}>
              <DraAndDrop onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}
