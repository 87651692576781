import React from 'react'
import { Button, Modal, useDisclosure } from '@chakra-ui/react'
import Voting from '../../assets/svg/voting'
import VotingMain from './main'

interface IVotingModal {
  diagnosticID: string
}
export default function VotingModal({ diagnosticID }: IVotingModal) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        onClick={onOpen}
        fontSize={14}
        leftIcon={<Voting />}
        variant={'outline'}
        border={'solid #CC61FF'}
        color={'#CC61FF'}
      >
        Voting results
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <VotingMain diagnosticID={diagnosticID} />
      </Modal>
    </>
  )
}
