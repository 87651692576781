import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react'
import React, { KeyboardEvent, useState } from 'react'
import Arrow from '../../../assets/svg/arrow'
import { useSendCellComments } from '../../../api/api_hooks/comments'
import { useGetPrams } from '../../../utils/getPrams'

interface ICommentInput {
  refetch?: () => void
}

export default function CommentInput({ refetch }: ICommentInput) {
  const cellId = useGetPrams('cellId')
  const toast = useToast()

  const [comment, setComment] = useState('')
  const { mutateAsync } = useSendCellComments()

  const sendComment = () => {
    if (comment.length) {
      setComment('')
      mutateAsync({ cellId, comment })
        .then(() => refetch && refetch())
        .catch(() =>
          toast({
            title: 'Something went wrong try again',
            position: 'top',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        )
    }
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendComment()
    }
  }

  return (
    <div>
      <InputGroup size="sm">
        <Input
          value={comment}
          onKeyDown={(e) => handleKeyPress(e)}
          onChange={(e) => setComment(e.target.value)}
          p={'20px 15px'}
          borderRadius={20}
          _placeholder={{ color: 'brand.gray' }}
          placeholder="Write your thoughts..."
        />
        <InputRightElement onClick={sendComment}>
          <IconButton
            mr={2.5}
            mt={2.5}
            colorScheme="#999FA9"
            aria-label="Sent comment"
            icon={
              <Box
                style={{ rotate: '90deg' }}
                bg={'brand.gray'}
                borderRadius={30}
              >
                <Arrow fill="#fff" size={25} />
              </Box>
            }
          />
        </InputRightElement>
      </InputGroup>
    </div>
  )
}
