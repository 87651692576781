import React from 'react'

function Home() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.5 22V12h6v10M3.5 9l9-7 9 7v11a2 2 0 01-2 2h-14a2 2 0 01-2-2V9z"
      ></path>
    </svg>
  )
}

export default Home
