import { Box, Container, Heading } from '@chakra-ui/react'
import ProfileForm from '../../components/forms/profileForm'

export default function MyInfo() {
  return (
    <Box p={{ base: '32px 16px', lg: '32px 100px' }}>
      <Heading as="h2" size="xl">
        My Profile
      </Heading>
      <Container>
        <ProfileForm />
      </Container>
    </Box>
  )
}
