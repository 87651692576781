import { Navigate, createBrowserRouter } from 'react-router-dom'
import { ProtectedRouts } from './protected'
import EnterForm from '../pages/EnterPages/EnterForm'
import LoginForm from '../components/forms/loginForm'
import RegisterForm from '../components/forms/registerForm'
import RegisterConfirmed from '../components/forms/registerForm/registerConfirmed'
import MyInfo from '../pages/MyInfo'
import Patients from '../pages/Patients'
import PatientCard from '../components/patientsBox/patientCard'
import PatientsList from '../components/patientsBox/patientsList'
import MyCollaboratory from '../components/patientsBox/myCollaboratory'

export const router = createBrowserRouter([
  {
    element: <EnterForm />,
    children: [
      {
        path: 'login',
        element: <LoginForm />,
      },
      {
        path: 'register',
        element: <RegisterForm />,
      },
      {
        path: 'confirm-email',
        element: <RegisterConfirmed />,
      },
    ],
  },
  {
    element: <ProtectedRouts />,
    children: [
      {
        path: '/my-info',
        element: <MyInfo />,
      },
      {
        path: '/patients',
        children: [
          {
            element: <Patients />,
            children: [
              {
                path: '',
                element: <PatientsList />,
              },
              {
                path: 'myCollaboratory',
                element: <MyCollaboratory />,
              },
            ],
          },
          {
            path: ':id',
            element: <PatientCard />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/my-info" replace />,
  },
])
