import { Box, Text } from '@chakra-ui/react'
import React from 'react'

interface IConclusion {
  conclusion: string | null
}

export default function ConclusionBox({ conclusion }: IConclusion) {
  return (
    <Box p={'4px 8px'} bg={'brand.opacityPurple'} borderRadius={10}>
      {conclusion?.length ? (
        <Box>
          <Text fontWeight={600} fontSize="sm">
            Conclusion:{' '}
            <Text as={'span'} fontWeight={400} fontSize="sm">
              {conclusion}
            </Text>
          </Text>
        </Box>
      ) : (
        <Text fontWeight={600} fontSize="sm">
          No Conclusion given
        </Text>
      )}
    </Box>
  )
}
