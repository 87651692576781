import mem from 'mem'
import { publicAxios } from '.'

const useRefreshTokenFn = async () => {
  const { refreshToken, userId } = JSON.parse(
    localStorage.getItem('auth') || '{}'
  )
  try {
    const response = await publicAxios.post('/auth/refresh-token', {
      userId: userId,
      refreshToken: refreshToken,
    })
    const { data } = response
    if (!data.accessToken) {
      localStorage.setItem('auth', JSON.stringify({}))
    }
    localStorage.setItem('auth', JSON.stringify(data))
    return Promise.resolve(data)
  } catch (error: any) {
    localStorage.setItem('auth', JSON.stringify({}))
    return Promise.reject({ error: error.request })
  }
}

const maxAge = 10000
export const memoizedRefreshToken = mem(useRefreshTokenFn, {
  maxAge,
})
