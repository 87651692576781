import { usePosts } from "../../query/usePublickPost";
import { saveTokens } from "../../axios/interceptor";

const useAuthAPI = () => {
    const useRegistration = (token: string) => {
        const inv = token ? `auth/sign-up?invasionToken=${token}` : 'auth/sign-up'
        const post = usePosts(inv)
        return post
    }
    const useLogin = () => {
        const post = usePosts('/auth/sign-in')
        if (post.isSuccess) {
            saveTokens(post.data?.data)
        }
        return post
    }
    const useConfirmEmail = () => {
        const post = usePosts('/auth/sign-up/verify')
        return post
    }

    return { useRegistration, useLogin, useConfirmEmail }
};
  
export default useAuthAPI;