import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface IControlInput {
  label?: string
  isInvalid?: boolean
  placeholder?: string
  errorMessage?: string
  register?: UseFormRegisterReturn
}

export default function ControlPassword({
  label,
  isInvalid,
  placeholder,
  errorMessage,
  register,
  ...props
}: IControlInput) {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <FormControl
      {...props}
      isInvalid={isInvalid}
      color={'brand.lightGray'}
      mt={label && 4}
    >
      {label && <Text>{label}</Text>}
      <InputGroup>
        <Input
          {...register}
          borderRadius={8}
          placeholder={placeholder}
          _placeholder={{ color: 'brand.lightGray' }}
          background={'white'}
          type={show ? 'text' : 'password'}
          mt={1}
        />
        <InputRightElement width="4.5rem">
          <Button mt={2} h="1.75rem" size="sm" onClick={handleClick}>
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
