import React from 'react'

function EmptyFile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#737A86"
        d="M21.512 8.368c-.521-.709-1.247-1.54-2.044-2.337-.796-.796-1.627-1.522-2.337-2.042C15.922 3.102 15.336 3 15 3H6.375A1.878 1.878 0 004.5 4.875v17.25C4.5 23.159 5.341 24 6.375 24h14.25a1.878 1.878 0 001.875-1.875V10.5c0-.336-.102-.922-.988-2.132zm-3.105-1.275c.72.72 1.284 1.368 1.7 1.907H16.5V5.393c.538.417 1.189.98 1.908 1.7zM21 22.125a.38.38 0 01-.375.375H6.375A.38.38 0 016 22.125V4.875a.38.38 0 01.375-.375H15v5.25a.75.75 0 00.75.75H21v11.625z"
      ></path>
      <path
        fill="#737A86"
        d="M14.132.989C12.922.102 12.336 0 12 0H3.375A1.878 1.878 0 001.5 1.875v17.25c0 .906.645 1.663 1.5 1.837V1.875c0-.202.172-.375.375-.375h11.41a15.605 15.605 0 00-.653-.511z"
      ></path>
    </svg>
  )
}

export default EmptyFile
