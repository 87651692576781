import { Box, Center, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import NewTestModal from '../../newTest'
import { useGetAllDiagnostics } from '../../../../api/api_hooks/diagnostics'
import DiagnosticResultsCard from '../../diagnosticResultsCard'
import EmptyFile from '../../../../assets/svg/emptyFile'
import Pagination from '../../../pagination'

export default function PatientMain() {
  const { data } = useGetAllDiagnostics()
  return (
    <Box h={'100%'} overflowY={'scroll'}>
      <Flex justifyContent={'space-between'}>
        <Box>
          <Heading as="h2" size="xl" fontFamily={'Poppins'}>
            Patient history
          </Heading>
          <Heading as="h4" size="md" mt={5} fontFamily={'Poppins'}>
            Diagnostic results
          </Heading>
        </Box>
        <Box>
          <NewTestModal />
        </Box>
      </Flex>

      <VStack mt={5} gap={5}>
        {data?.data?.length ? (
          <>
            {data?.data.map((diagnostic: any) => (
              <DiagnosticResultsCard
                diagnostic={diagnostic}
                key={diagnostic.id}
              />
            ))}
          </>
        ) : (
          <Center gap={3} mt={'150px'}>
            <EmptyFile />
            <Text fontSize="2xl" color={'brand.lightGrayText'}>
              No results available for this patient yet
            </Text>
          </Center>
        )}
        <Pagination data={data} pageName={'diagnosticsPage'} />
      </VStack>
    </Box>
  )
}
