import { publicAxios } from '../axios'
import { useMutation } from '@tanstack/react-query'

export function usePosts(url: string) {
  return useMutation({
    mutationKey: [url],
    mutationFn: (postData) => {
      return publicAxios.post(url, postData)
    },
  })
}
