import { Box, useToast } from '@chakra-ui/react'
import ControlInput from '../formComponents/ControlInput'
import ConfirmButton from '../formComponents/ConfirmButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import ControlSelect from '../formComponents/ControlSelect'
import ControlDataPicker from '../formComponents/ControlDataPicker'
import { NewPatient } from '../../../utils/schema'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  useCreatePatient,
  useEditPatient,
  useGetPatient,
  usePatientsList,
} from '../../../api/api_hooks/patients'
import { useEffect } from 'react'

type Inputs = {
  fullName: string
  gender: string
  dateOfBirth: Date
  address: string
}

export default function PatientForm({ id, onClose }: any) {
  const toast = useToast()

  const {
    mutate: mutateEditPatient,
    isSuccess: isSuccessEditPatient,
    data: patchData,
  } = useEditPatient()
  const { mutate, isSuccess, data, isError } = useCreatePatient()
  const { refetch } = usePatientsList()
  const { data: patientData, refetch: getPatientAgain } = useGetPatient(id)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<Inputs>({
    resolver: yupResolver(NewPatient),
  })
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    patientData
      ? mutateEditPatient({ id: patientData.id, data })
      : mutate(data as unknown as void)
  }

  useEffect(() => {
    if (patientData) {
      setValue('fullName', patientData.fullName)
      setValue('gender', patientData.gender)
      setValue('dateOfBirth', patientData.dateOfBirth)
      setValue('address', patientData.address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData])

  useEffect(() => {
    if (isSuccess || isSuccessEditPatient) {
      refetch()
      getPatientAgain()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isSuccessEditPatient])

  useEffect(() => {
    if (data || patchData) {
      toast({
        title: `${id ? 'Update' : 'Create'} patient - success`,
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, patchData])

  if (isError) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <>
      <Box minW={270} padding={{ base: '0px', lg: '0px 100px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlInput
            register={{ ...register('fullName') }}
            label="Name and Surname"
            placeholder="Name Surname"
            isInvalid={!!errors.fullName}
            errorMessage={errors.fullName && errors.fullName.message}
          />
          <ControlSelect
            register={{ ...register('gender') }}
            label="Gender"
            placeholder="Select patient gender"
            isInvalid={!!errors.gender}
            errorMessage={errors.gender && errors.gender.message}
            options={['male', 'female']}
          />
          <ControlDataPicker
            register={{ ...register('dateOfBirth') }}
            label="Date of Birth"
            placeholder="Choose date"
            isInvalid={!!errors.dateOfBirth}
            errorMessage={errors.dateOfBirth && errors.dateOfBirth.message}
            control={control}
          />
          <ControlInput
            register={{ ...register('address') }}
            label="Address"
            placeholder="Address"
            isInvalid={!!errors.address}
            errorMessage={errors.address && errors.address.message}
          />
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Box width={{ sm: '200px' }}>
              <ConfirmButton text={`${id ? 'Update' : 'Create'} Patient`} />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  )
}
