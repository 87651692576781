import React from 'react'

function Flask() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#CC61FF"
        d="M5.89.348a.625.625 0 01.855-.22l.693.41 7.706 4.423a.625.625 0 01-.622 1.084l-.703-.403-1.254 2.165-.002-.002-2.131-1.226a.625.625 0 10-.624 1.084l2.13 1.225-.8 1.383-.002-.001-3.503-2.015a.625.625 0 10-.623 1.083l3.5 2.014-.737 1.275-.002-.002-2.178-1.252a.625.625 0 00-.623 1.083l2.176 1.252-.933 1.61a4.052 4.052 0 01-6.709.44 4.022 4.022 0 01-.295-4.47L6.811 1.62l-.005-.002-.697-.412a.625.625 0 01-.22-.857zm9.777 12.819c.92 0 1.666-.778 1.666-1.736 0-.6-.652-1.401-1.141-1.917a.718.718 0 00-1.05 0c-.49.516-1.142 1.317-1.142 1.917 0 .958.746 1.736 1.667 1.736z"
      ></path>
    </svg>
  )
}

export default Flask
