import { Button, Flex } from '@chakra-ui/react'
import React from 'react'

interface IActiveButton {
  confirm?: React.MouseEventHandler<HTMLButtonElement> | undefined
  cancel?: React.MouseEventHandler<HTMLButtonElement> | undefined
  confirmButtonText?: string
  cancelButtonText?: string
}

export default function ActiveButton({
  confirm,
  cancel,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
}: IActiveButton) {
  return (
    <Flex gap={3} w={'100%'}>
      <Button variant={'outline'} bg={''} w={'100%'} onClick={cancel}>
        {cancelButtonText}
      </Button>
      <Button bg={'#CC61FF'} color={'white'} w={'100%'} onClick={confirm}>
        {confirmButtonText}
      </Button>
    </Flex>
  )
}
