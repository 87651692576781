import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import VotingSlider from './votingSlider'
import VotingChart from './votingChart'
import VotingComment from './votingComment'
import { useGetImageCellsNew2 } from '../../api/api_hooks/diagnostics'
import { useGetPrams } from '../../utils/getPrams'

interface IVotingMain {
  diagnosticID: string
}

export default function VotingMain({ diagnosticID }: IVotingMain) {
  const page = useGetPrams('diagnosticsPage')
  const { result } = useGetImageCellsNew2(
    `diagnostics/${diagnosticID}/shared-collections/image-cells?page=${page || 1}&sortFiled=string&sortDirection=ASC`
  )

  return (
    <Box fontFamily={'Poppins'}>
      <ModalOverlay />
      <ModalContent minW={'70%'}>
        <ModalHeader display={'flex'} gap={3}>
          <Text pb={2}>Questionable cases under verification</Text>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody pb={5}>
          <VotingSlider images={result?.data} />
          <VotingChart />
          <VotingComment />
        </ModalBody>
      </ModalContent>
    </Box>
  )
}
