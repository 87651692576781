import { Navigate } from 'react-router-dom'
import DashboardLayout from '../pages/DashboardLayout'
import { getSavedAuthData } from '../api/axios/interceptor'

export const ProtectedRouts = () => {
  const { accessToken } = getSavedAuthData()

  if (accessToken) {
    return <DashboardLayout />
  } else return <Navigate to="/login" replace />
}
