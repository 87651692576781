import { Box, Heading, Spacer } from '@chakra-ui/react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'

const tabs = [
  { title: 'My Patients', path: '/patients' },
  { title: 'My Collaboratory', path: 'myCollaboratory' },
]

export default function PatientsBox() {
  let location = useLocation()

  const heading = location.pathname.includes('myCollaboratory')
    ? 'Shared with me'
    : 'My Patients'

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} pb={3}>
        <Heading as="h2" size="xl">
          {heading}
        </Heading>
        <Box
          display={'flex'}
          gap={1}
          background={'white'}
          borderRadius={7}
          padding={0.5}
        >
          {tabs.map((tab) => (
            <NavLink key={tab.path} to={tab.path} end>
              {({ isActive }) => {
                return (
                  <Box
                    background={isActive ? 'brand.purple' : ''}
                    color={isActive ? 'white' : ''}
                    borderRadius={7}
                    p={'10px 40px'}
                  >
                    {tab.title}
                  </Box>
                )
              }}
            </NavLink>
          ))}
        </Box>
      </Box>
      <Outlet />
      <Spacer />
    </>
  )
}
