import {
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
import Document from '../../../../assets/svg/document'
import ViewCellImagesModal from '../viewCellImagesModal/viewCellImagesModal'
import React from 'react'

interface ISharedCase {
  data: any
}

export default function SharedCase({ data }: ISharedCase) {
  const Name = () => {
    return data.isOwner ? (
      <Text mb={2} fontWeight={600} fontSize={'xl'}>
        {`To `}
        {data?.invitations?.map((invitation: any, i: number, arr: any[]) => (
          <React.Fragment key={invitation.id}>
            {invitation?.receiverDoctor?.contactName}
            {i === arr?.length - 1 ? '' : ', '}
          </React.Fragment>
        ))}
      </Text>
    ) : (
      <Text
        mb={2}
        fontWeight={600}
        fontSize={'xl'}
      >{`From ${data?.diagnostic?.patient?.doctor?.contactName}`}</Text>
    )
  }

  return (
    <Card>
      <CardBody>
        <Box>
          <Name />
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Flex gap={2} alignItems={'center'}>
              <Document />
              <Text>Review provided cells</Text>
            </Flex>
            <ViewCellImagesModal images={data?.imageCells} />
          </Flex>
          <Divider mt={3} />
        </Box>
        <Flex gap={3} wrap={'wrap'} mt={3}>
          {data?.imageCells?.map((cell: any) => (
            <Image
              key={cell.id}
              w={120}
              h={120}
              borderRadius={8}
              alt=""
              src={cell?.mediaUrl}
            />
          ))}
        </Flex>
      </CardBody>
    </Card>
  )
}
