import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react'

import ResultItem from './ResultItem/iindex'
import Flask from '../../../assets/svg/flask'
import ConclusionBox from '../conclusionBox'
import CellImageModal from '../cellImageModal'
import Check from '../../../assets/svg/check'
import VotingModal from '../../votingModal'
import moment from 'moment'
import Circle from '../../../assets/svg/circle'
import React from 'react'

interface PatientRecord {
  diagnostic: {
    cells: number
    cellularAnalysisImagesCount: number
    hasMalarial: null | boolean
    id: string
    patientId: string
    infectedRbcCount: number
    uninfectedRbcCount: number
    estimatedParasitemiaPercent: number
    commentText: null | string
    decision: null | string
    declineReason: any
    process: string
    createdAt: string
    updatedAt: string
    patient: {
      id: string
      fullName: string
      dateOfBirth: string
      gender: 'male' | 'female'
      address: string
      doctorId: string
      createdAt: string
      updatedAt: string
    }
  }
}

const DECLINE_REASON: any = {
  poorQuality: 'Poor quality result',
  prescreenSample: 'Rescreen the sample',
  disagreeResults: 'Disagree with the proposed results',
  needExpertise: 'Need additional expertise',
}

export default function DiagnosticResultsCard({ diagnostic }: PatientRecord) {
  const malariaStatus =
    diagnostic.decision === 'malaria' || diagnostic.decision === 'negative'
  if (diagnostic.process === 'inProgress') {
    return (
      <Card fontFamily={'Poppins'} w={'100%'} maxW={1200}>
        <CardBody>
          Please wait, testing is progress...
          <Text fontSize="md">id: {diagnostic.id}</Text>
          <Text fontSize="md">
            From: {moment(diagnostic.createdAt).format('DD MMM YYYY')}
          </Text>
        </CardBody>
      </Card>
    )
  }

  if (diagnostic.process === 'failed') {
    return (
      <Card fontFamily={'Poppins'} w={'100%'} maxW={1200}>
        <CardBody>
          Diagnostic failed
          <Text fontSize="md">id: {diagnostic?.id}</Text>
          <Text fontSize="md">
            From: {moment(diagnostic?.createdAt).format('DD MMM YYYY')}
          </Text>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card fontFamily={'Poppins'} w={'100%'} maxW={1200}>
      <CardHeader pb={0} display={'flex'} gap={2} alignItems={'center'}>
        <Flask />
        <Text fontSize="xl" fontWeight={500}>
          Malaria blood screening
        </Text>
      </CardHeader>

      <CardBody>
        <Stack spacing="4">
          <Flex gap={1}>
            <Text fontSize="md" fontWeight={600}>
              Results for:
            </Text>
            <Text
              fontSize="md"
              color={'brand.lightGrayText'}
            >{`${moment(diagnostic.createdAt).format('DD.MM.YY')}`}</Text>
          </Flex>
          <ResultItem
            title="Infected RBCs:"
            info={`${diagnostic.infectedRbcCount}`}
          />
          <ResultItem
            title="Uninfected Blood Components:"
            info={`${diagnostic.uninfectedRbcCount}`}
          />
          <ResultItem
            title="Estimated parasitaemia:"
            info={`${diagnostic.estimatedParasitemiaPercent}%`}
          />
          <Box display={'flex'} gap={1}>
            <Text fontWeight={600} fontSize="sm">
              Analysis Summary:
            </Text>
            <Text
              fontSize="sm"
              fontWeight={400}
            >{`Analyzed Cells: ${diagnostic?.cells} / Analyzed Fields: ${diagnostic?.cellularAnalysisImagesCount}`}</Text>
          </Box>
        </Stack>
        <Divider m={'20px 0px'} />
        <ConclusionBox conclusion={diagnostic?.commentText} />
      </CardBody>
      <CardFooter pt={0}>
        <Box
          display={'flex'}
          justifyContent={malariaStatus ? '' : 'space-between'}
          w={'100%'}
          gap={2}
        >
          <CellImageModal
            buttonText="View Cell Images"
            diagnosticID={diagnostic.id}
            bg={'brand.purple'}
            colorScheme="purple"
            color={'white'}
          />
          <VotingModal diagnosticID={diagnostic.id} />
        </Box>
        {malariaStatus && (
          <Box display={'flex'} alignItems={'center'}>
            {diagnostic.hasMalarial ? (
              <Flex>
                <Text
                  maxW={150}
                  fontSize={14}
                  fontWeight={500}
                  display={'flex'}
                  alignItems={'center'}
                  gap={2}
                >
                  <Check fill="black" />
                  Malaria detected
                </Text>
                <CellImageModal
                  bg={''}
                  color={'Black'}
                  fontWeight={500}
                  buttonText="Edit"
                  diagnosticID={diagnostic.id}
                  icon={false}
                />
              </Flex>
            ) : (
              <Flex>
                <Text
                  maxW={150}
                  w={150}
                  fontSize={14}
                  fontWeight={500}
                  display={'flex'}
                  alignItems={'center'}
                  gap={2}
                >
                  {diagnostic.hasMalarial ? (
                    <Check fill="black" />
                  ) : (
                    <Circle stroke="black" />
                  )}
                  Malaria negative
                </Text>
                <CellImageModal
                  bg={''}
                  color={'Black'}
                  fontWeight={500}
                  buttonText="Edit"
                  diagnosticID={diagnostic.id}
                  icon={false}
                />
              </Flex>
            )}
          </Box>
        )}
      </CardFooter>
      {diagnostic?.declineReason?.length &&
        diagnostic.decision === 'declined' && (
          <Box p={5} pt={0}>
            <Text fontWeight={600}>Decline reasons:</Text>
            <Text>
              {diagnostic?.declineReason?.map(
                (item: string, i: number, arr: any[]) => (
                  <React.Fragment
                    key={i}
                  >{`${DECLINE_REASON[item]}${i === arr?.length - 1 ? '' : ', '}`}</React.Fragment>
                )
              )}
            </Text>
          </Box>
        )}
    </Card>
  )
}
