import React, { useContext, useState } from 'react'
import {
  Center,
  Spacer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import CustomTab from '../../CustomTab'
import { CommentContext } from '../../../context/commentContext'
import { useGetVoteForCell } from '../../../api/api_hooks/vote'
import { useGetPrams } from '../../../utils/getPrams'
import CommentList from '../../commentComponents/commentList'

export default function VotingComment() {
  const [vote, setVote] = useState('malaria')
  const { checkCommentType } = useContext(CommentContext)
  const cellId = useGetPrams('cellId')
  const { result } = useGetVoteForCell(
    `/image-cells/${cellId}/votes?vote=${vote}`
  )
  const { result: commentsCount } = useGetVoteForCell(
    `/image-cells/${cellId}/votes/statistics`
  )
  return (
    <>
      <Tabs
        mt={20}
        onChange={(index) => checkCommentType(index)}
        variant="soft-rounded"
        h={'100%'}
        fontFamily={'Poppins'}
      >
        <Center>
          <TabList
            bg={'#E8E8E8'}
            width={'fit-content'}
            borderRadius={20}
            padding={0.5}
          >
            <CustomTab onClick={() => setVote('malaria')}>
              Positive{' '}
              {commentsCount?.malaria ? `(${commentsCount?.malaria})` : ''}
            </CustomTab>
            <CustomTab onClick={() => setVote('negative')}>
              Negative{' '}
              {commentsCount?.negative ? `(${commentsCount?.negative})` : ''}
            </CustomTab>
            <CustomTab onClick={() => setVote('declined')}>
              Declined{' '}
              {commentsCount?.declined ? `(${commentsCount?.declined})` : ''}
            </CustomTab>
          </TabList>
        </Center>

        <TabPanels>
          <TabPanel>
            <CommentList commentList={result} />
          </TabPanel>
          <TabPanel>
            <CommentList commentList={result} />
          </TabPanel>
          <TabPanel>
            <CommentList commentList={result} />
          </TabPanel>
        </TabPanels>
        <Spacer />
      </Tabs>
    </>
  )
}
