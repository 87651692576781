import { Box, Heading, Highlight, Text } from '@chakra-ui/react'

interface IFormHead {
  title: string
  subTitle?: string
  highlightQuery?: string
}

export default function FormHead({
  title,
  subTitle,
  highlightQuery = '',
}: IFormHead) {
  return (
    <Box>
      <Heading as="h3" fontSize={32}>
        <Highlight query={highlightQuery} styles={{ color: 'brand.purple' }}>
          {title}
        </Highlight>
      </Heading>
      <Text fontSize="lg" color={'brand.lightGray'} mt={'14px'}>
        {subTitle}
      </Text>
    </Box>
  )
}
