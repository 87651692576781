import { AxiosResponse } from "axios";
import axiosClient from ".";
import { memoizedRefreshToken } from "./refreshToken";

interface tokensData{
    accessToken: string, refreshToken: string
}

export const getSavedAuthData = () =>
	JSON.parse(localStorage.getItem('auth') || '{}');

const saveAuthData = (data: tokensData) => {
	localStorage.setItem('auth', JSON.stringify(data));
};

export const saveTokens = (tokensData: tokensData) => {
    if(tokensData) {
        const { accessToken } = tokensData;
		axiosClient.defaults.headers.common.Authorization = `${accessToken}`;
        saveAuthData(tokensData);
        return Promise.resolve(tokensData);
    }
};

axiosClient.interceptors.request.use(
	async (config: any) => {
		const { accessToken } = getSavedAuthData();
		if (accessToken) {
			config.headers = {
				...config.headers,
				authorization: `${accessToken}`,
			};
			return config;
		}
	},
	(error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async (error) => {
		const config = error?.config;
		if (error.response.status === 401 && !config._retry) {
			const result = await memoizedRefreshToken();
			config._retry = true;
			if (result?.accessToken) {
				const header = `${result?.accessToken}`;
				axiosClient.defaults.headers.common.Authorization = header;
				config.headers.Authorization = header;
			}
			return axiosClient(config);
		}
		return Promise.reject(error);
	}
);