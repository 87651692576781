import { Box, Wrap } from '@chakra-ui/react'
import CellImageBox from './CellImageBox'
import { useGetImageCellsNew2 } from '../../../../../api/api_hooks/diagnostics'
import Pagination from '../../../../pagination'
import { useGetPrams } from '../../../../../utils/getPrams'
import { useEffect } from 'react'

interface IInfectedOnly {
  diagnosticID?: string
  rerenderTrigger?: any
}

export default function InfectedOnly({
  diagnosticID,
  rerenderTrigger,
}: IInfectedOnly) {
  const page = useGetPrams('infectedPage')
  const { result, refetch } = useGetImageCellsNew2(
    `diagnostics/${diagnosticID}/image-cells?page=${page}`
  )

  useEffect(() => {
    if (rerenderTrigger === 1) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerenderTrigger])

  return (
    <Box>
      {/* minH={621} */}
      <Wrap spacing="20px" justify={'center'} mb={10}>
        {result?.data?.map((cell: any) => (
          <CellImageBox key={cell.id} cell={cell} updateImageCells={refetch} />
        ))}
      </Wrap>
      <Pagination data={result} pageName={'infectedPage'} />
    </Box>
  )
}
