import React from 'react'

function Arrow({ fill = '#344054', size = 20 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M16.333 10H4.667M10.5 15.833L4.667 10 10.5 4.167"
      ></path>
    </svg>
  )
}

export default Arrow
