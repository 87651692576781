import React, { useState } from 'react'
import DeclineModal from '../../../resultModal/DeclineModal'
import { useSendVote } from '../../../../api/api_hooks/vote'
import { useGetPrams } from '../../../../utils/getPrams'
import { useToast } from '@chakra-ui/react'

export default function DeclineCollaboratory() {
  const cellId = useGetPrams('cellId')
  const toast = useToast()

  const [trigger, setTrigger] = useState(0)

  const { mutateAsync } = useSendVote()

  const confirm = (data: any) => {
    if (data.multiple.length && data.thoughts) {
      mutateAsync({
        id: cellId,
        data: {
          commentText: data.thoughts,
          vote: 'declined',
          declineReason: data.multiple,
        },
      })
        .then(() => {
          toast({
            title: 'Diagnostic - decline is send',
            position: 'top',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          setTrigger(trigger + 1)
        })
        .catch((e) => {
          const alreadyVoted = e.response.data.statusCode === 409
          toast({
            title: alreadyVoted
              ? 'You have already voted'
              : `Something went wrong - try again`,
            position: 'top',
            status: alreadyVoted ? 'info' : 'error',
            duration: 5000,
            isClosable: true,
          })
        })
    } else {
      toast({
        title: 'Write a comment',
        position: 'top',
        status: 'info',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return <DeclineModal closeTrigger={trigger} confirm={confirm} />
}
