import { useQuery } from "@tanstack/react-query"
import axiosClient from "../axios"
import { getSavedAuthData } from "../axios/interceptor";

export function useGet(url: string) {
  const {userId} = getSavedAuthData();
  
  const invalidPath = !url.includes('//') &&
  !url.includes('/undefined') &&
  !url.includes('/null')
  
  return useQuery({
    enabled: invalidPath,
    staleTime: 50000,
    queryKey: [url + userId],
    retry: 1,
    queryFn: async () => {
      const { data } = await axiosClient.get(url)
      return data
    },
  })
}
  