import React, { useEffect, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

import { FreeMode, Navigation, Thumbs } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export default function VotingSlider({images}) {
  const navigate = useNavigate()
  const [thumbsSwiper, setThumbsSwiper] = useState('')
  

  const queryCellId = (image) => {
    navigate(`?cellId=${image?.id}`)
  }

  useEffect(() => {
    navigate(`?cellId=${images?.[0]?.id}`)
    return () => navigate('?cellId=')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images])

  return (
    images?.length && <Box pl={10} pr={10}>
      <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={7}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
          navigation
      >
        {images?.map((image, i) => (
          <SwiperSlide key={image + i} onClick={() => queryCellId(image)}>
            <img alt="" src={image.mediaUrl} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[Thumbs]}
        className="mySwiper2"
      />
    </Box>
  )
}
