import React from 'react'

function Check({ fill = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.167 5L8 14.167 3.833 10"
      ></path>
    </svg>
  )
}

export default Check
