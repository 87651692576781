import axios from 'axios';

const axiosClient = axios.create({
  baseURL: 'https://api-staging.careflai.com/api/',
});

export const publicAxios = axios.create({
  baseURL: 'https://api-staging.careflai.com/api/',
  headers: {
    "Content-type": "application/json"
  }
});


export default axiosClient;
