import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import SideBar from '../../components/sideBar'

export default function DashboardLayout() {
  return (
    <Box display={'flex'} h={'100%'}>
      <Box bg="brand.sideBarBlack">
        <SideBar />
      </Box>
      <Box w="100%" background={'#F1EFEE'}>
        <Outlet />
      </Box>
    </Box>
  )
}
