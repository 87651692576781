import React, { ReactElement } from 'react'
import {
  MenuList,
  Menu,
  MenuButton,
  Button,
  MenuItem,
  Image,
} from '@chakra-ui/react'

interface ICellImageBox {
  cell: any
  children?: ReactElement<typeof MenuItem> | ReactElement<typeof MenuItem>[]
}
export default function CellImageBox({ cell, children }: ICellImageBox) {
  return (
    <Menu>
      <MenuButton
        _hover={{ border: '1px solid red' }}
        as={Button}
        w={120}
        h={120}
        bg={'grey'}
        borderRadius={8}
        display={'flex'}
        p={0}
      >
        <Image
          alt=""
          src={cell?.mediaUrl}
          maxH={120}
          w={'100%'}
          borderRadius={8}
        />
      </MenuButton>
      <MenuList>{children}</MenuList>
    </Menu>
  )
}
