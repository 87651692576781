import { useMutation } from "@tanstack/react-query"
import axiosClient from "../../../axios"

export function useDeleteFromInfected() {
    const deletePatient = useMutation({
        mutationKey: ['/deleteCellFromInfected'],
        mutationFn: (id: string) => {
            return axiosClient.delete(`/image-cells/${id}`)
        },
    })
    return deletePatient
}

export function useAddToInfected() {
    const addToInfected = useMutation({
        mutationKey: ['addToInfected'],
        mutationFn: (data: any) => {
            return axiosClient.post(`/image-cells`, data)
        },
    })
    return addToInfected
}