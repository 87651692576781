import { useParams, useSearchParams } from 'react-router-dom'
import { useGet } from '../../query/useGet'
import { usePostProtected } from '../../query/useProtectedPost'
import { useMutation } from '@tanstack/react-query'
import axiosClient from '../../axios'

export function usePatientsList() {
  const [searchParams] = useSearchParams()
  const page = searchParams.get('patientsPage')
  const patientsList = useGet(`/patients?page=${page}&limit=20`)
  return patientsList
}

export function useCreatePatient() {
  const patient = usePostProtected(`/patients`)
  return patient
}

export function useGetPatient(id?: string) {
  let { id: paramID } = useParams()
  const selectedId = paramID || id
  const patient = useGet(`/patients/${selectedId}`)
  return patient
}

export function useDeletePatient() {
  const deletePatient = useMutation({
    mutationKey: ['/deletePatients'],
    mutationFn: (id: string) => {
      return axiosClient.delete(`/patients/${id}`)
    },
  })
  return deletePatient
}

export function useEditPatient() {
  const deletePatient = useMutation({
    mutationKey: ['/editPatients'],
    mutationFn: ({ id, data }: any) => {
      return axiosClient.patch(`/patients/${id}`, data)
    },
  })
  return deletePatient
}
