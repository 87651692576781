import axiosClient from '../../axios'
import { useQuery } from '@tanstack/react-query'
import { getSavedAuthData } from '../../axios/interceptor'

export function useMyProfile() {
  const { userId } = getSavedAuthData()
  return useQuery({
    staleTime: 50000,
    queryKey: [userId],
    queryFn: async () => {
      const { data } = await axiosClient.get('/users/profile')
      return data
    },
  })
}
