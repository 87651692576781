import React from 'react'

function Circle({ stroke = '#CC61FF' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_884_3323)">
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_884_3323">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Circle
