import { Box, Grid, GridItem } from '@chakra-ui/react'
import PatientListItem from './patientListItem'
import CustomGridItem from './customGridItem'
import PatientFormModal from '../patientFormModal'
import { usePatientsList } from '../../../api/api_hooks/patients'
import Pagination from '../../pagination'

export default function PatientsList() {
  const { data } = usePatientsList()

  return (
    <Box>
      <Grid
        overflow={'scroll'}
        h="80vh"
        templateRows="repeat(5, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <CustomGridItem pl={5} color={'brand.lightGrayText'}>
          Name
        </CustomGridItem>
        <CustomGridItem color={'brand.lightGrayText'}>Gender</CustomGridItem>
        <CustomGridItem color={'brand.lightGrayText'}>
          Date of birth
        </CustomGridItem>
        <CustomGridItem color={'brand.lightGrayText'}>Address</CustomGridItem>
        <CustomGridItem justifyItems={'end'} pr={5}>
          <PatientFormModal />
        </CustomGridItem>
        <GridItem colSpan={5} display={'grid'} gap={4}>
          {data?.data?.map((item: any, i: number) => (
            <PatientListItem
              key={item.id}
              patientData={{ id: item.id, ...item }}
            />
          ))}
        </GridItem>
      </Grid>
      <Pagination data={data} pageName="patientsPage" />
    </Box>
  )
}
