import * as yup from 'yup'

export const email = yup
.object({
  email: yup
  .string()
  .email('Invalid email format')
  .required('Email is required')
})
.required()

export const loginSchema = yup
.object({
  email: yup
    .string()
    .email('Invalid email format')
    .max(50, 'Email too long')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password too short')
    .max(50, 'Email too long')
    .required('Password is required'),
  keepMeLogged: yup.boolean(),
})
.required()

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

export const RegistrationSchema = yup
.object({
  companyName: yup
    .string()
    .min(1, 'Name too short')
    .max(30, 'Name too long')
    .required('Company name is required'),
  contactName: yup
    .string()
    .min(1, 'Name too short')
    .max(30, 'Name too long')
    .required('Contact Name is required'),
  phoneNumber: yup
    .string()
    .min(7, 'Phone number too short')
    .max(15, 'Phone number too long')
    .required('Contact Name is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
  speciality: yup
    .string()
    .max(30, 'Name too long')
    .required('Speciality is required'),
  email: yup
    .string()
    .email('Invalid email format')
    .max(50, 'Email too long')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password too short')
    .max(50, 'Phone number too long')
    .required('Password is required'),
  confirmPassword: yup.string()
    .test('passwords-match', 'Passwords must match', function(value){
      return this.parent.password === value
    }).required('Confirm password is required')

})
.required()

export const MyProfile = yup
.object({
  companyName: yup
    .string()
    .min(1, 'Name too short')
    .max(30, 'Name too long')
    .required('Company name is required'),
  contactName: yup
    .string()
    .min(1, 'Name too short')
    .max(30, 'Name too long')
    .required('Contact Name is required'),
  phoneNumber: yup
    .string()
    .min(7, 'Phone number too short')
    .max(15, 'Phone number too long')
    .required('Contact Name is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
  email: yup
    .string()
    .email('Invalid email format')
    .max(50, 'Email too long')
    .required('Email is required'),
  avatar: yup.mixed().test("fileSize", "The file is too large", (value: any) => {
    if (value) {
      if (!value.length) return true // attachment is optional
      return value[0].size <= 2000000
    }
  }),
})
.required()

export const NewPatient = yup
.object({
  fullName: yup
    .string()
    .min(1, 'Name too short')
    .max(30, 'Name too long')
    .required('Name and Surname is required'),

  gender: yup
    .string()
    .required('Gender is required')
    .oneOf(["male", "female"]),

  dateOfBirth: yup
    .date()
    .required('Date of birth is required'),
    
  address: yup
    .string()
    .min(1, 'Address too short')
    .max(50, 'Address too long')
    .required('Address is required'),

})
.required()