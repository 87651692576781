import React, { useState } from 'react'
import { useGetAllDiagnostics } from '../../../../api/api_hooks/diagnostics'
import { useMalariaDetected } from '../../../../api/api_hooks/diagnostics/results'
import MalariaNegativeModal from '../../../resultModal/MalariaNegativeModal'
import { useToast } from '@chakra-ui/react'

interface IProps {
  diagnosticID?: string
}
export default function MalariaNegativeCellImage({ diagnosticID }: IProps) {
  const toast = useToast()
  const [trigger, setTrigger] = useState(0)

  const { refetch } = useGetAllDiagnostics()
  const { mutate, isError } = useMalariaDetected()
  const [diagnosText, setDiagnosText] = useState<string>('')

  const sendDiagnostic = () => {
    if (diagnosText) {
      mutate({
        id: diagnosticID,
        data: {
          commentText: diagnosText,
          decision: 'negative',
        },
      })
      toast({
        title: 'Diagnostic - negative is send',
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      refetch()
      setDiagnosText('')
      setTrigger(trigger + 1)
    } else {
      toast({
        title: 'Write a comment',
        position: 'top',
        status: 'info',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  if (isError) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <MalariaNegativeModal
      closeTrigger={trigger}
      onChange={(e) => setDiagnosText(e.target.value)}
      confirm={sendDiagnostic}
    />
  )
}
