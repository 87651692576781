import { useMutation, useQuery } from '@tanstack/react-query'
import axiosClient from '../../axios'

export function useGetShareCell(path: string) {
  const invalidPath =
    !path.includes('//') &&
    !path.includes('/undefined') &&
    !path.includes('/null')

  const getVoteForCell = useQuery({
    enabled: invalidPath,
    staleTime: 50000,
    queryKey: [path],
    retry: 1,
    queryFn: async () => {
      return await axiosClient.get(path)
    },
  })

  const result = getVoteForCell.data?.data

  return { result, ...getVoteForCell }
}

export function useSendVote() {
  const sendVote = useMutation({
    gcTime: 50000,
    mutationFn: ({ id, data }: any) => {
      return axiosClient.post(`/image-cells/${id}/votes`, data)
    },
  })
  return sendVote
}

export function useGetVoteForCell(path: string) {
  const invalidPath =
    !path.includes('//') &&
    !path.includes('/undefined') &&
    !path.includes('/null')

  const getVoteForCell = useQuery({
    enabled: invalidPath,
    staleTime: 50000,
    queryKey: [path],
    retry: 1,
    queryFn: async () => {
      return await axiosClient.get(path)
    },
  })

  const result = getVoteForCell.data?.data

  return { result, ...getVoteForCell }
}
