import { Flex, Text } from '@chakra-ui/react'

interface IPatientData {
  title: string
  info: string
}

export default function ResultItem({ title, info }: IPatientData) {
  return (
    <Flex>
      <Text w={'100%'} maxW={230} fontSize="sm">
        {title}
      </Text>
      <Text fontSize="sm">{info}</Text>
    </Flex>
  )
}
