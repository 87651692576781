import { useMutation, useQuery } from "@tanstack/react-query"
import axiosClient from "../../axios"

export function useSendCellComments() {
    const getSendCellComments = useMutation({
        gcTime: 50000,
        mutationFn: ({cellId, comment}: any) => {
          return axiosClient.post(`/image-cells/${cellId}/comments`, {commentText: comment})
        },
    })
    return getSendCellComments
}

export function useGetCellComments(path: string) {
  const invalidPath =
    !path.includes('//') &&
    !path.includes('/undefined') &&
    !path.includes('/null')

  const getVoteForCell = useQuery({
    enabled: invalidPath,
    staleTime: 50000,
    queryKey: [path],
    retry: 1,
    queryFn: async () => {
      return await axiosClient.get(path)
    },
  })

  const result = getVoteForCell.data?.data

  return { result, ...getVoteForCell }
}
  