import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import ActiveButton from '../../patientsBox/activeButton/ActiveButton'
import Plus from '../../../assets/svg/plus'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'

interface IModal {
  confirm: (data: any) => void
  closeTrigger: any
}

export default function DeclineModal({ closeTrigger, confirm }: IModal) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { register, handleSubmit } = useForm()

  const onSubmit = (data: any) => {
    confirm(data)
  }

  useEffect(() => {
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeTrigger])

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={
          <Box style={{ rotate: '135deg' }}>
            <Plus fill="#000" />
          </Box>
        }
        variant={'outline'}
      >
        Decline result
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display={'flex'} gap={3}>
              <Box>
                <Text>Reasons for Decline</Text>
              </Box>
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={5}>
              <FormControl as="fieldset" pb={5}>
                <Stack direction="column">
                  <Checkbox value={'poorQuality'} {...register('multiple')}>
                    Poor quality result
                  </Checkbox>
                  <Checkbox value={'prescreenSample'} {...register('multiple')}>
                    Rescreen the sample
                  </Checkbox>
                  <Checkbox value={'disagreeResults'} {...register('multiple')}>
                    Disagree with the proposed results
                  </Checkbox>
                  <Checkbox value={'needExpertise'} {...register('multiple')}>
                    Need additional expertise
                  </Checkbox>
                </Stack>
              </FormControl>

              <Textarea
                {...register('thoughts')}
                placeholder="Write your thoughts..."
              />
            </ModalBody>
            <Center>
              <ModalFooter w={'100%'}>
                <ActiveButton
                  confirm={handleSubmit(onSubmit)}
                  confirmButtonText="Submit"
                />
              </ModalFooter>
            </Center>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}
