import React from 'react'

function Voting() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#CC61FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.5 12.5a1.666 1.666 0 01-1.667 1.667h-10L2.5 17.5V4.167A1.667 1.667 0 014.167 2.5h11.666A1.666 1.666 0 0117.5 4.167V12.5z"
      ></path>
    </svg>
  )
}

export default Voting
