import { useMutation } from '@tanstack/react-query'
import axiosClient from '../../../axios'

export function useMalariaDetected() {
  const newDiagnostics = useMutation({
    mutationKey: ['MalariaDetected'],
    mutationFn: ({ id, data }: any) => {
      return axiosClient.patch(`/diagnostics/${id}`, data)
    },
  })
  return newDiagnostics
}
